import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  generateCertificate,
  generateInternshipLetter,
} from "../../../../../services/operations/courseDetailsAPI";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateAccountType } from "../../../../../services/operations/profileAPI";

const UserTable = ({ users }) => {
  // Initialize accountType state with an empty object
  const [accountType, setAccountType] = useState({});
  const [selectedCourse, setSelectedCourse] = useState({});
  const { token } = useSelector((state) => state.auth);
  const handleSelectedCourse = (courseId, newCourseName) => {
    setSelectedCourse({ ...selectedCourse, [courseId]: newCourseName });
  };

  const handleAccountTypeChange = (userId, newAccountType) => {
    // Handle account type change here
    // console.log(`User ID: ${userId}, New Account Type: ${newAccountType}`);
    setAccountType({ ...accountType, [userId]: newAccountType });
  };

  const getProgressColor = (progress) => {
    if (progress >= 80) {
      return "bg-green-200";
    } else if (progress >= 50) {
      return "bg-yellow-200";
    } else {
      return "bg-red-200";
    }
  };

  const handleUpdateAccountType = async (mentorId, newAccountType) => {
    try {
      console.log(mentorId,newAccountType)
      await updateAccountType({ mentorId, newAccountType }, token);
    } catch (error) {
      console.error("Error updating mentor account type:", error);
    }
  };

  const handleGenerateInternshipLetter = async (email, internDuration=1) => {
    const response = await generateInternshipLetter(
      { email, internDuration },
      token
    );
    toast.success(response);
  };

  const handleGenerateCertificate = async (courseName, email) => {
    // console.log(courseName, email);
    const response = await generateCertificate({ courseName, email }, token);
    toast.success(response);
  };

  return (
    <div className="overflow-x-auto m-10">
      <table className="min-w-full">
        <thead className="text-white">
          <tr>
            <th className="px-6 py-3 bg-yellow-20 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Profile Image
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              First Name
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Last Name
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Active
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Account Type
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Courses Enrolled
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Avg Course Progress
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Created At
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Courses
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Button
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Certificates
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Internship Letter
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users?.map((user) => (
            <tr
              key={user?._id}
              className="transition-all hover:bg-gray-100 shadow-md hover:shadow-lg"
            >
              <td className="px-6 py-4 whitespace-no-wrap">
                <Link to={`/user/${user._id}`}>
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          user?.image ||
                          `https://api.dicebear.com/5.x/initials/svg?seed=${user.firstName} ${user.lastName}`
                        }
                        alt={`${user.firstName} ${user.lastName}`}
                      />
                    </div>
                  </div>
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">{user.firstName}</td>
              <td className="px-6 py-4 whitespace-no-wrap">{user.lastName}</td>
              <td className="px-6 py-4 whitespace-no-wrap">{user.email}</td>
              <td className="px-6 py-4 whitespace-no-wrap">
                {user.active ? "Yes" : "No"}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                <select
                  value={accountType[user._id] || user.accountType}
                  onChange={(e) =>
                    handleAccountTypeChange(user._id, e.target.value)
                  }
                  className="border-2 border-gray-200 rounded-md py-1 px-2"
                >
                  <option value="Student">Student</option>
                  <option value="Admin">Admin</option>
                  <option value="Instructor">Instructor</option>
                </select>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                {/* Display the courses the user is enrolled in */}
                <ul>
                  {user.courses?.map((course, courseIndex) => (
                    <li key={course._id}>
                      <span
                        className={`rounded-md px-2 py-1 flex ${getProgressColor(
                          (user?.courseProgress[courseIndex]?.completedVideos
                            ?.length *
                            100) /
                            course?.courseContent?.length
                        )}`}
                      >
                        {course.courseName} (
                        {Math.round(
                          (user.courseProgress[courseIndex]?.completedVideos
                            ?.length *
                            100) /
                            course.courseContent?.length
                        )}{" "}
                        %)
                      </span>
                    </li>
                  ))}
                </ul>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                {/* Calculate average course progress */}
                {user?.courses?.length > 0 ? (
                  <>
                    {Math.round(
                      user?.courses?.reduce(
                        (total, course, index) =>
                          total +
                          ((user.courseProgress[index]?.completedVideos
                            ?.length *
                            100) /
                            course.courseContent?.length || 0),
                        0
                      ) / user.courses?.length
                    )}
                    %
                  </>
                ) : (
                  "N/A"
                )}
              </td>

              <td className="px-6 py-4 whitespace-no-wrap">
                {new Date(user.createdAt).toLocaleString()}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                <select
                  value={
                    selectedCourse[user?.courses[0]?._id] ||
                    user.courses[0]?.courseName
                  }
                  onChange={(e) =>
                    handleSelectedCourse(user.courses[0]?._id, e.target.value)
                  }
                  className="border-2 border-gray-200 rounded-md py-1 px-2"
                >
                  {user?.courses?.map((course) => (
                    <option key={course?._id} value={course?.courseName}>
                      {course?.courseName}
                    </option>
                  ))}
                </select>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                <button
                  onClick={() =>
                    handleUpdateAccountType(user._id, user.accountType)
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Update
                </button>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                <button
                  onClick={() =>
                    handleGenerateCertificate(
                      selectedCourse[user.courses[0]?._id],
                      user.email
                    )
                  }
                  className="bg-blue-500 hover:bg-yellow-200 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Generate
                </button>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap">
                <button
                  onClick={() => handleGenerateInternshipLetter(user.email, 1)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
