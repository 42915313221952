// Icons Import
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

// Image and Video Import
// @ts-ignore
import Banner from "../assets/Images/banner.mp4";
// Component Imports
import Footer from "../components/common/Footer";
import ReviewSlider from "../components/common/ReviewSlider";
import CTAButton from "../components/core/HomePage/Button";
import CodeBlocks from "../components/core/HomePage/CodeBlocks";
import ExploreMore from "../components/core/HomePage/ExploreMore";
import HighlightText from "../components/core/HomePage/HighlightText";
import InstructorSection from "../components/core/HomePage/InstructorSection";
import LearningLanguageSection from "../components/core/HomePage/LearningLanguageSection";
import TimelineSection from "../components/core/HomePage/TimelineSection";
import { useSelector } from "react-redux";
import CourseSlider from "../components/core/Catalog/CourseSlider";
import React from "react";

function Home() {
  // @ts-ignore
  const { course } = useSelector((state) => state.course);
  // console.log("course is n",course)
  return (
    <div>
      {/* Section 1 */}
      <div className="relative mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 text-white">
        {/* Become a Instructor Button */}
        <Link to={"/signup"}>
          <div className="group mx-auto mt-16 w-fit rounded-full bg-richblack-800 p-1 font-bold text-richblack-200 drop-shadow-[0_1.5px_rgba(255,255,255,0.25)] transition-all duration-200 hover:scale-95 hover:drop-shadow-none">
            <div className="flex flex-row items-center gap-2 rounded-full px-10 py-[5px] transition-all duration-200 group-hover:bg-yellow-100 text-white">
              <p>Become a Mentor</p>
              <FaArrowRight />
            </div>
          </div>
        </Link>

        {/* Heading */}
        <div className="text-center text-4xl font-semibold">
        Feel Free To Learn !
          
          <HighlightText text={"'Unlock Your Potential, Learn Anytime, Anywhere.'"} />
        </div>

        {/* Sub Heading */}
        <div className="-mt-3 w-[90%] text-center text-lg font-bold text-richblack-300">
        Dive into the world of coding at your own pace, from the comfort of your home or on-the-go. Our courses are designed for flexibility, allowing you to tailor your learning to fit your lifestyle.
        </div>

        {/* CTA Buttons */}
        <div className="mt-8 flex flex-row gap-7">
          <CTAButton active={true} linkto={"/about"}>
            Learn More
          </CTAButton>
          <CTAButton active={false} linkto={"/dashboard/categoryList"}>
            Book a Demo
          </CTAButton>
        </div>

        {/* Video */}
        <div className="mx-3 my-7 shadow-[10px_-5px_50px_-5px] shadow-blue-200">
          <video
            className="shadow-[20px_20px_rgba(255,255,255)]"
            muted
            loop
            autoPlay
          >
            <source src={Banner} type="video/mp4" />
          </video>
        </div>

        {/* Code Section 1  */}
        <div>
          <CodeBlocks
            position={"lg:flex-row"}
            heading={
              <div className="text-4xl font-semibold">
                Unlock your
                <HighlightText text={"coding potential"} /> StudyHub.tech
              </div>
            }
            subheading={
              "Embark on a coding journey like no other with our online courses. Whether you're a budding developer or a seasoned programmer, our platform offers a gateway to boundless learning."
            }
            ctabtn1={{
              btnText: "Try it Yourself",
              link: "/catalog/web-development",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              link: "/signup",
              active: false,
            }}
            codeColor={"text-yellow-25"}
            codeblock={`<!DOCTYPE html>\n <html lang="en">\n<head>\n<title>Welcome to Spark-Tech</title>\n</head>\n<body>\n<h1><a href="/">Header</a></h1>\n<nav> <a href="/coding">Coding</a> <a href="/love">Love</a> <a href="/Life">LetMeLoveYou</a>\n</nav>\n</body>`}
            backgroundGradient={<div className="codeblock1 absolute"></div>}
          />
        </div>

        {/* Course Cards with slider */}
        <HighlightText text={"Top Courses"} className="text-9xl font-semibold decoration-dotted decoration-yellow-100"/>


        <CourseSlider Courses={course} />

        {/* Code Section 2 */}
        <div>
          <CodeBlocks
            position={"lg:flex-row-reverse"}
            heading={
              <div className="w-[100%] text-4xl font-semibold lg:w-[50%]">
                Start
                <HighlightText text={"Unleashing Your Creativity"} />
              </div>
            }
            subheading={
              "Coding is more than just lines of text; it's a canvas for your imagination. Let your creativity flourish as you build, experiment, and bring your ideas to life."
            }
            ctabtn1={{
              btnText: "Continue Lesson",
              link: "/catalog/frontend",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              link: "/about",
              active: false,
            }}
            codeColor={"text-white"}
            codeblock={`import React from "react";\n import Love from "./Love.jsx";\nimport TypeAnimation from "react-type";\nimport { Coding } from "react-icons/coding";\n\nconst Home = () => {Family\nreturn (\n<div>LoveIsLife</div>\n)\n}\nexport default Family;`}
            backgroundGradient={<div className="codeblock2 absolute"></div>}
          />
        </div>

        {/* Explore Section */}
        <ExploreMore />
      </div>

      {/* Section 2 */}
      <div className="bg-richblack-900-5 text-richblack-300 mt-3">
        <div className="">
          {/* Explore Full Catagory Section */}
          <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8">
            <div className="lg:h-[150px]"></div>
            <div className="flex flex-row gap-7 text-white lg:mt-8">
              <CTAButton active={true} linkto={"/dashboard/categoryList"}>
                <div className="flex items-center gap-2">
                  Explore Full Catalog
                  <FaArrowRight />
                </div>
              </CTAButton>
              <CTAButton active={false} linkto={"/about"}>
                Learn More
              </CTAButton>
            </div>
          </div>
        </div>

        
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 ">
          {/* <div className="mb-10 mt-[-100px] flex flex-col justify-between gap-7 lg:mt-20 lg:flex-row lg:gap-0">
            <div className="text-4xl font-semibold lg:w-[45%] ">
              Get the skills you need for a{" "}
              <HighlightText text={"job that is in demand."} />
            </div>
            <div className="flex flex-col items-start gap-10 lg:w-[40%]">
              <div className="text-[20px]">
              The road to success in Spark-Tech is not a sprint; it's a marathon of dedication, resilience, and continuous learning. Keep moving forward, one step at a time."
              </div>
              <CTAButton active={true} linkto={"/dashboard/catalogList"}>
                <div className="">Learn More</div>
              </CTAButton>
            </div>
          </div> */}

          {/* <TimelineSection /> */}
          <LearningLanguageSection />
        </div>
        
      </div>

      {/* Section 3 */}
      <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-richblack-900 text-white">
        {/* Become a instructor section */}
        <InstructorSection />
        




        {/* Reviws from Other Learner */}
        <h1 className="text-center text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1>
        <ReviewSlider />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
