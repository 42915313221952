// @ts-nocheck
import React, { useEffect, useRef } from "react";
import HighlightText from "./HighlightText";
import CTAButton from "../../../components/core/HomePage/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import ajitImg from "../../../assets/Images/ajit.png";
import RanjanImg from "../../../assets/Images/Ranjan.jpg";
import yashImg from "../../../assets/Images/575.png";
import SunnyImg from "../../../assets/Images/Sunny.png";

SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const LearningLanguageSection = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  // const handleSlideNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext();
  //   }
  // };

  // const handleSlidePrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev();
  //   }
  // };

  return (
    <div className="text-center my-10">
      <div className="text-2xl md:text-4xl lg:text-5xl font-semibold mx-auto">
        Ignite Your Passion for learning <br /> any language{" "}
        <HighlightText text={" with StudyHub.tech"} />
      </div>
      <div className="text-center text-richblack-300 font-medium lg:w-[75%] mx-auto leading-6 text-base mt-3">
        Passion fuels learning. Ignite your passion <br /> with our engaging
        courses and embark on a journey of knowledge and growth. <br /> Fuel
        Your Passion <HighlightText text={" with StudyHub.tech"} />
      </div>
      <div className="mt-8 mx-auto">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={25}
          loop={true}
          modules={[Autoplay, FreeMode, Navigation, Pagination]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={800}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          className="max-w-md md:max-w-lg lg:max-w-xl mx-auto"
        >
          <SwiperSlide>
            <div className="text-white w-fit mx-auto">
              <img
                src={RanjanImg}
                alt="Ranjan"
                className="rounded-full w-48 h-48 object-cover mx-auto"
              />
              <HighlightText
                className="font-semibold text-lg mt-4"
                text={"Raaz"}
              />
              <p className="text-sm mt-2 text-center break-words w-full mx-auto">
                Hi everyone! 👋 I'm Raaz from the enchanting lands of
                Bihar, India. <br />
                <span className="font-bold">Figma</span> for crafting
                pixel-perfect designs, <br />
                <span className="font-bold">FullStack JAVA Developer</span> 
                "Building Seamless Solutions from Frontend to Backend."🌐 <br />
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="text-white w-fit mx-auto">
              <img
                src={SunnyImg}
                alt="Sunny"
                className="rounded-full w-48 h-48 object-cover mx-auto"
              />
              <HighlightText
                className="font-semibold text-lg mt-4"
                text={"Sunny"}
              />
              <p className="text-sm mt-2 text-center break-words w-full mx-auto">
                Hi everyone! 👋 I'm Sunny from the lands of Braves,
                Bihar, India. <br />
                <span className="font-bold">DataBase</span> "Powering Insights, One Query at a Time." <br />
                <span className="font-bold">FullStack Web Developer</span> to unravel the
                mysteries of Web Application,🌐 <br />
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="text-white">
              <img
                src={yashImg}
                alt="Yash"
                className="rounded-full w-48 h-48 object-cover mx-auto"
              />
              <HighlightText
                className="font-semibold text-lg mt-4"
                text={"Yash Gupta"}
              />
              <p className="text-sm mt-2 text-center break-words w-full mx-auto">
                Hey there! 💻 I'm Yash Gupta hailing from the picturesque hills
                of Nepal. <br />
                Falling in love with both{" "}
                <span className="font-bold">code ☕️</span> and{" "}
                <span className="font-bold">life 💞</span>, <br />
                Come, let's write lines of code and tales of love together! 🌟
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="text-white">
              <img
                src={ajitImg}
                alt="Instructor Gupta"
                className="rounded-full w-48 h-48 object-cover mx-auto"
              />
              <HighlightText
                className="font-semibold text-lg mt-4"
                text={"Ajit Sah"}
              />
              <p className="text-sm mt-2 text-center w-full mx-auto">
                Namaste! 🌟 I am Ajit Sah, a curious mind from the enchanting
                land of Nepal. <br />
                <span className="font-bold">Logic Building</span> and{" "}
                <span className="font-bold">Technological Understanding</span>.
                🧠💡 <br />
                Let's build logic together and craft a future filled with
                endless possibilities! 🚀
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div
          className="swiper-button-next relative"
          onClick={handleSlideNext}
          style={{ right: "10px" }} // Adjust position as needed
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-richblack-200 bg-yellow-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
        <div
          className="swiper-button-prev relative"
          onClick={handleSlidePrev}
          style={{ left: "10px" }} // Adjust position as needed
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-richblack-200 bg-yellow-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div> */}
      </div>

      <div className="w-fit mx-auto mt-7">
        <CTAButton active={true} linkto={"/about"}>
          <div className="">Learn More</div>
        </CTAButton>
      </div>
    </div>
  );
};

export default LearningLanguageSection;
